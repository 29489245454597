/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/line-clamp'),
    ],
  }
  ```
*/

import financiamiento from "./images/financiamiento.jpg";
import horario from "./images/horario.jpg";
import ambiente from "./images/amigable.jpg";

const posts = [
  {
    id: 2,
    title: "Financiamiento y Planes de Pago",
    href: "#",
    description:
      " Contamos con múltiples opciones de pago, como efectivo, débito y crédito. Además, ofrecemos convenios con empresas y PYMES, seguros complementarios dentales y planes de financiamiento personalizados, para que puedas acceder a nuestros tratamientos sin preocuparte por el costo. ",
    imageUrl: financiamiento,
  },
  {
    id: 1,
    title: "Horarios Flexibles",
    href: "#",
    description:
      " Sabemos que tu tiempo es valioso, por eso nos adaptamos a tus necesidades y disponibilidad. Ofrecemos horarios de atención flexibles, incluyendo atenciones en la mañana y tarde para que puedas recibir nuestros servicios cuando más te acomode.",
    imageUrl: horario,
  },

  {
    id: 3,
    title: "Ambiente Amigable y Cálido",
    href: "#",
    description:
      "Nuestra clínica está diseñada para que te sientas cómodo y relajado. Contamos con un ambiente acogedor y amigable, donde nuestro personal te atenderá con calidez y empatía. ¿Quieres un café? ¡Te esperamos para compartir uno! Nuestro objetivo es que te sientas como en casa mientras cuidamos de tu salud bucal.",
    imageUrl: ambiente,
  },
];

export default function Values() {
  return (
    <div id="diferenciales" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comodidad y seguridad
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            No dudes en visitarnos o contactarnos para agendar una cita. Estamos
            ansiosos por conocerte y ayudarte a mejorar tu sonrisa y bienestar.
          </p>
        </div>
        <div className="justify-start mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                {/*  here are the required changes */}
                <div className="group relative flex-col items-start">
                  <h3 className="mt-5 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <span className="absolute inset-0" />
                    {post.title}
                  </h3>
                  <p className="mt-5 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

/* This example requires Tailwind CSS v3.0+ */

import Layout from "../../components/layout";
import Header from "./header";
import Product from "./product";

const ProductPage = () => {
  return (
    <Layout>
      <Header />
      <Product />
    </Layout>
  );
};

export default ProductPage;

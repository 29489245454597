export default function CallToAction() {
  return (
    <div className="relative isolate overflow-hidden bg-sky-800">
      <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Mejora tu vida con una sonrisa
            <br />
            saludable y deslumbrante
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Deja que nuestro servicio te guíe hacia una sonrisa vibrante y
            saludable, mejorando tu bienestar general y autoestima en un entorno
            acogedor.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://api.whatsapp.com/send?phone=56973813665"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-sky-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Reserva hoy
            </a>
            <a
              href="#faq"
              className="text-sm font-semibold leading-6 text-white "
            >
              Conoce más <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#1e40af" />
            <stop offset={1} stopColor="#172554" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

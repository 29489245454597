const IconRow = ({ icon, text }) => {
  return (
    <div
      className="flex flex-row items-center justify-center mb-4 rounded-md p-3"
      style={{ backgroundColor: "#A29AB0" }}
    >
      <img src={icon} style={styles.icon} />
      <div className="w-4" />
      <p className="text-center text-lg font-semibold" style={styles.data}>
        {text}
      </p>
    </div>
  );
};

export const UnderConstruction = () => {
  return (
    <div
      className="flex-1 items-center justify-items-center h-screen w-screen"
      style={styles.container}
    >
      <div className="mx-auto w-fit">
        <img
          className="mx-auto align-middle"
          style={styles.img}
          src={require("./img/logo.jpeg")}
          alt="Under Construction"
        />
        <h1
          className="text-center font-extrabold text-3xl"
          style={styles.heading}
        >
          Sitio en Construcción
        </h1>
        <p className="text-center text-lg font-semibold" style={styles.text}>
          Pronto nueva página de atención al cliente
        </p>
        <p
          className="text-center text-lg font-semibold -mt-2 mb-4"
          style={styles.text}
        >
          para facilitar tu salud dental
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "white",
  },
  heading: {
    color: "#1C1A1E",
  },
  text: { color: "#686472" },
  data: { color: "#54505B" },
  img: {
    height: 384,
    width: 384,
    margin: 0,
  },
  icon: {
    height: 24,
    width: 24,
    margin: 0,
  },
};

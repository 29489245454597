/* This example requires Tailwind CSS v3.0+ */
import React from "react";
import Header from "./header";
import Features from "./features";
import Pricing from "./pricing";
import Faq from "./faq";
import Products from "./products";
import Layout from "../../components/layout";
import Benefits from "./benefits";
import Testimonial from "./testimonial";
import Stats from "./stats";
import About from "./about";
import CallToAction from "./call-to-action";
import CallToActionTwo from "./call-to-action-two";

const LandingPage = () => {
  return (
    <Layout>
      <Header />
      <Features />
      <CallToAction />
      <About />
      <Stats />
      <Testimonial />
      {/* <Pricing /> */}
      <CallToActionTwo />
      <Benefits />
      <Faq />
    </Layout>
  );
};

export default LandingPage;

import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Header() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-sky-100/20">
        <div className="mx-auto max-w-7xl pb-24 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-12 lg:pb-56">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <img
                    className="w-12 mb-8"
                    src={require("../../images/icon-lumina-dark.png")}
                    alt="Your Company"
                  />
                  <a
                    href="https://goo.gl/maps/Znv4LZqVehPvtqeR9"
                    className="inline-flex space-x-6"
                  >
                    <span className="rounded-full bg-sky-600/10 px-3 py-1 text-sm font-semibold leading-6 text-sky-600 ring-1 ring-inset ring-sky-600/10 flex flex-col items-center justify-center">
                      Encuentranos
                    </span>
                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>General Ordóñez #155, Of 506. Maipú.</span>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </a>
                </div>
                <h1 className="mt-8 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Cuida tu salud bucal con expertos de corazón.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Tratamientos personalizados y transparentes. Honestidad y
                  compromiso para lograr resultados estéticos y funcionales.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="https://api.whatsapp.com/send?phone=56973813665"
                    className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    Reserva una hora
                  </a>
                  <a
                    href="#servicios"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Conoce más <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-12 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-sky-600/10 ring-1 ring-sky-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="relative">
                <div className="mx-auto max-w-3xl md:mx-0 md:max-w-none">
                  <img
                    className="mx-auto align-middle rounded-xl"
                    src={require("./images/hero.jpg")}
                    alt="una dentista sonriendo"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}

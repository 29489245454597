import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "¿Qué servicios ofrece la clínica dental?",
    answer:
      "La clínica dental ofrece servicios de odontología general, rehabilitación oral, endodoncia, periodoncia, odontopediatría y ortodoncia convencional. También planea añadir alineadores invisibles (Invisalign) y tratamientos de estética facial como botox y ácido hialurónico.",
  },
  {
    question: "¿Donde están ubicados?",
    answer:
      "La clinica se encuentra ubicada en General Ordoñez 155, oficina 506 (Quinto Piso). Frente a la plaza monumento, en el corazón de la comuna de Maipú, Santiago. A pasos del metro Plaza de Maipú.",
  },
  {
    question: "¿Cuentan con Estacionamiento?",
    answer:
      "El edificio cuenta con estacionamientos limitados para visitas. Preguntanos con anticipación para indicarte disponibilidad de estos. En caso contrario, existen estacionamientos privados en alrededores del edificio.",
  },
  {
    question:
      "¿Cuál es el enfoque de la clínica dental en cuanto a la atención al paciente?",
    answer:
      "La clínica se enfoca en brindar una atención honesta y de calidad, dedicando tiempo a cada paciente y enseñándoles cómo mejorar su salud oral y general.",
  },

  {
    question:
      "¿Qué técnicas de manejo del dolor utiliza la Clínica en caso de ser necesario?",
    answer:
      "La clínica utiliza anestesia con estrategia sin dolor. Aplicando a la anestesia una temperatura igual a la corporal, para minimizar la sensación invasiva del bloqueo anestésico. También utilizamos algunos tips y anestesia tópica, para que ni te enteres que realizamos esta maniobra.",
  },
  {
    question:
      "¿Cómo aborda la clínica el miedo y la vergüenza que algunos pacientes pueden sentir?",
    answer:
      "La clínica se enfoca en evaluar a cada persona, individualizarla y comprenderla al máximo. Sabemos que no es fácil venir al dentista. Por ende, te acompañamos en el proceso. Todo de manera cordial, segura y resguardando tu privacidad.",
  },
  {
    question:
      "¿Qué estrategias utiliza la clínica para fomentar la higiene bucal en sus pacientes?",
    answer:
      "La clínica ofrece clases gratuitas de instrucción de higiene progresivas y continuas, en las que enseñan a los pacientes a utilizar correctamente sus elementos de higiene personal y a reforzar hábitos saludables.",
  },
  {
    question:
      "¿Qué opciones de financiamiento y planes de pago ofrece la clínica?",
    answer:
      "La clínica acepta pagos en efectivo, débito y crédito. Además, ofrece convenios con instituciones financieras para costear tratamientos de alineadores invisibles en cuotas.",
  },

  {
    question:
      "¿Existe algún programa de fidelidad para los pacientes habituales?",
    answer:
      "La clínica cuenta con un programa de beneficios con descuentos progresivos de hasta el 25% para pacientes que asistan a sus reevaluaciones de manera constante. Así como también para los que refieren a sus cercanos. Además, realiza sorteos de elementos de higiene personal y descuentos especiales entre su cartera de pacientes.¡Premiamos tu esfuerzo y tu cuidado constante!",
  },
  {
    question: "¿La clínica dental ofrece tratamientos de ortodoncia?",
    answer:
      "Sí, la clínica ofrece ortodoncia convencional y alineadores invisibles (Invisalign) a sus servicios. Todos realizados por especialistas altamente calificados y en constante actualización",
  },
];

export default function Example() {
  return (
    <div className="bg-sky-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
            Preguntas y respuestas frecuentes
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-sky-300">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

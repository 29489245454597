import carlos from "../../images/customers/carlos.jpeg";
import joaquin from "../../images/customers/joaquin.jpeg";
import luminaIcon from "../../images/icon-lumina.png";

export default function Features() {
  return (
    <div
      id="servicios"
      className="relative overflow-hidden bg-white pt-16 pb-32"
    >
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-sky-800">
                  <img
                    src={luminaIcon}
                    className="h-8 w-8 text-white"
                    alt="icon"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Servicios Dentales Integrales
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Ofrecemos una amplia gama de servicios, que incluyen
                  odontología general, endodoncia, periodoncia y
                  odontopediatría. Nos enfocamos en la prevención y educación,
                  asegurando que cada paciente reciba el más alto estándar de
                  atención y resultados duraderos.
                </p>
                <div className="mt-6">
                  <a
                    href="https://api.whatsapp.com/send?phone=56973813665"
                    className="inline-flex rounded-lg bg-sky-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-sky-600 hover:bg-sky-700 hover:ring-sky-700"
                  >
                    Reservar hora
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Excelente atención del equipo, atentos y preocupados
                    de los procedimientos. Manejan valores asequibles, cuentan
                    con buen equipamiento técnico e instalaciones. &rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={carlos}
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Carlos Jorquera
                      <div className="text-sm text-gray-400">Paciente</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={require("./images/servicios-dentales.jpg")}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-sky-800">
                  <img
                    alt="icon"
                    src={luminaIcon}
                    className="h-8 w-8 text-white"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Ortodoncia Personalizada
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Nuestro enfoque en ortodoncia se centra en adaptar cada
                  tratamiento a las necesidades específicas del paciente. Tras
                  conocerte y evaluar específicamente tu situación, te
                  presentamos las alternativas de tratamiento más adecuadas y te
                  explicamos lo que puedes esperar de cada una, siempre de
                  manera honesta y transparente.
                </p>
                <div className="mt-6">
                  <a
                    href="https://api.whatsapp.com/send?phone=56973813665"
                    className="inline-flex rounded-lg bg-sky-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-sky-600 hover:bg-sky-700 hover:ring-sky-700"
                  >
                    Reserva aquí
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={require("./images/ortodoncia.jpg")}
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative pt-16">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-sky-800">
                  <img
                    alt="icon"
                    src={luminaIcon}
                    className="h-8 w-8 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Alineadores Invisibles Invisalign
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Somos pioneros en ofrecer los verdaderos alineadores
                  invisibles Invisalign, que combinan alta estética y eficacia
                  en el tratamiento. Escaneamos tu boca, adaptamos el
                  tratamiento a tus expectativas y te ofrecemos atractivas
                  opciones de financiamiento para que puedas acceder a esta
                  revolución digital en ortodoncia.
                </p>
                <div className="mt-6">
                  <a
                    href="https://api.whatsapp.com/send?phone=56973813665"
                    className="inline-flex rounded-lg bg-sky-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-sky-600 hover:bg-sky-700 hover:ring-sky-700"
                  >
                    Reservar tu sonrisa
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;ambiente muy grato, me explicaron todo muy bien,
                    preocupados de mi bienestar. Por mi parte, yo sigo viniendo
                    a clínica lumina. &rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={joaquin}
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Joaquin Alcaino
                      <div className="text-sm text-gray-400">Paciente</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={require("./images/invisalign.jpg")}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
